/* CustomToolbar.css */

.leaflet-buttons {
    margin: 2px;
    width: 40px;  /* Adjusted width for the icon */
    height: 40px; /* Adjusted height for the icon */
    padding: 8px;
    border: none;
    border-radius: 4px;
    background-color: #f0f0f0;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .leaflet-buttons:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .leaflet-divider {
    width: 100%;
    height: 1px;
    background-color: #ccc;
    margin: 5px 0;
  }
  
  .custom-toolbar {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }
  