
.css-13cymwt-control {
    @apply border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200 hover:border-slate-200 dark:hover:border-zink-500 #{!important};
    
    .css-1u9des2-indicatorSeparator {
        @apply bg-slate-200 dark:bg-zink-500;
    }
    
    .css-1p3m7a8-multiValue {
        @apply bg-custom-500;
        
        .css-wsp0cs-MultiValueGeneric {
            @apply text-white;
        }
    }
}

.css-1nmdiq5-menu {
    @apply bg-white dark:bg-zink-700 shadow-lg shadow-slate-200 dark:shadow-zink-500/20 #{!important};
    
    .css-d7l1ni-option {
        @apply bg-slate-100 dark:bg-zink-600;
    }
}

[aria-disabled="true"].css-16xfy0z-control {
    @apply border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200 hover:border-slate-200 dark:hover:border-zink-500 #{!important};
}

.css-t3ipsp-control {
    @apply bg-white dark:bg-zink-700 border-slate-200 dark:border-zink-500 #{!important};
}

.css-qbdosj-Input, .css-166bipr-Input, .css-1fdsijx-ValueContainer {
    @apply text-slate-700 dark:text-zink-100 #{!important};
}